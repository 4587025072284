// Here you can add other styles
.card,
.dropdown-menu,
.footer,
.header,
.sidebar {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px;
}

.sidebar-overlaid {
  box-shadow: rgb(50 50 93 / 2%) 0 2px 5px -1px, rgb(0 0 0 / 5%) 0 1px 3px -1px, 0 5px 6px -3px rgb(0 0 0 / 25%);
}

.card-header {
  border-bottom: 1px solid var(--cui-card-border-color);
}

.footer,
.header {
  border: 0;
}

.sidebar-brand {
  justify-content: space-between;
  padding: 0 $sidebar-nav-link-padding-x * .5;
}

.sidebar-toggler {
  flex: 0 0 2rem;
  background-color: transparent;

  &::before {
    width: 2rem;
  }

  &:hover {
    background-color: transparent;
  }
}

.sidebar-narrow-unfoldable:not(:hover) {
  .sidebar-brand {
    justify-content: center;
    padding: 0;
  }
  .sidebar-toggler {
    display: none;
  }
}

.simplebar-content {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}

.action_bar {
  padding: $action_bar-padding-y $action_bar-padding-x;
  margin-bottom: 0;
  background-color: $action_bar-bg;
  border-bottom: 1px solid $border-color;

  ol, ul {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    list-style: none;

    margin: 0;
    padding: 0;
  }

  .btn-group {
    vertical-align: top;
  }

  .btn {
    padding: 0 $input-btn-padding-x;
    vertical-align: top;
  }

  .action_bar-item {
    // The separator between breadcrumbs (by default, a forward-slash: "/")
    + .action_bar-item {
      padding-left: $action_bar-item-left-padding;

      &::before {
        display: inline-block; // Suppress underlining of the separator in modern browsers
        padding-right: $action_bar-item-right-padding;
        color: $action_bar-divider-color;
        content: $action_bar-divider;
      }
    }
  }
}

.scrollspy-evaluation {
  height: calc(100vh - 240px);
  overflow-x: hidden;
  overflow-y: auto;
  padding-right: $table-cell-padding-x;
}

.min-vh-8 {
  min-height:8vh !important
}

.min-vh-12 {
  min-height:12vh !important
}

.min-vh-22 {
  min-height:22vh !important
}

.rotate_90 {
  transform: rotate(90deg);
}

.rotate_180 {
  transform: rotate(180deg);
}

.flip_horizontal {
  transform: scaleX(-1);
}

.cursor_pointer {
  cursor: pointer;
}

.min-width-td {
  min-width: 90px;
}

.mt-button {
  margin-top: 1.4rem !important;
}

.hidden-first-column {
  tr > *:nth-child(1) {
    display: none;
  }
}

.overflow-x-hidden {
  overflow-x: hidden;
}
